/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Box, Container, Typography } from '@material-ui/core';

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer>
          {/* © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a> */}

          <div style={{ backgroundColor:'#222'}}>
  <Container>
    <Box pt={2}>
    <Grid container style={{ color:'#fff'}}>
      <Grid item sm={6}>
     <Typography variant="body2" paragraph>
     Copyright © {new Date().getFullYear()} Bharathvarsha Ventures.
          {` `}
          All Rights Reserved.
     </Typography>   
      </Grid>

      <Grid item sm={6} align="right">
      <Typography  gutterBottom variant="body2" align='right'> Designed & Developed by <a href="https://www.srkitsolutions.com/" rel="noopener noreferrer" target="_blank">SRK IT Solutions</a></Typography>
            
      </Grid>
    

    </Grid>
    </Box>
  </Container>
  </div>

        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
