import React from 'react';
import { Link } from "gatsby";
import { Menu } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Scrollspy from 'react-scrollspy'

export default class Antmenu extends React.Component {


  render() {
    return (
        <Scrollspy items={ ['aboutus', 'bill', 'finance', 'global', 'contact' ] } currentClassName="ant-menu-item-selected" onUpdate={
            (el) => {
            console.log(el)
            }
          }>
 
        
      <Menu  defaultSelectedKeys={['home']} style={{ backgroundColor:'rgb(250, 250, 250)', borderBottom:0 }} mode="horizontal">
        <Menu.Item key="home">
         <Link to="/">Home</Link> 
        </Menu.Item>
        <Menu.Item key="aboutus">
        <AnchorLink offset='50' href='#aboutus'> About Us</AnchorLink>
         
        </Menu.Item>

        {/* <Menu.Item key ="bill">
        <AnchorLink href='#bill'>  Bill-Discounting</AnchorLink>
       
        </Menu.Item> */}

        <Menu.Item key="finance">
        <AnchorLink href='#finance'> Financial Tools</AnchorLink>
        
        </Menu.Item>

        <Menu.Item key="global">
        <AnchorLink offset='50' href='#global'> Global Reach</AnchorLink>
        
        </Menu.Item>

        <Menu.Item key="contact">
        <AnchorLink href='#contact'>  Contact</AnchorLink>
      
        </Menu.Item>
        
      </Menu>
      </Scrollspy>
    );
  }
}